var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v("Rewards"),
      ]),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.addReward },
                  },
                  [_vm._v("Add")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    attrs: { color: "primary", type: "border" },
                    on: { click: _vm.onBackToRulesList },
                  },
                  [_vm._v("Back To Rules")]
                ),
                _c("vs-input", {
                  staticClass:
                    "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                  attrs: { placeholder: "Search..." },
                  on: { input: _vm.updateSearchQuery },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.rewardsList,
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              rowSelection: "single",
              pagination: false,
              context: _vm.context,
              suppressPaginationPanel: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }