var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex items-center justify-center cell-renderer-reward-checkbox-container",
    },
    [
      _c("vs-checkbox", {
        attrs: { disabled: "" },
        model: {
          value: _vm.params.data.isActive,
          callback: function ($$v) {
            _vm.$set(_vm.params.data, "isActive", $$v)
          },
          expression: "params.data.isActive",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }